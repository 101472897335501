export const DEFAULT_SYSTEM_PROJECT_SETTINGS = {
    Security: {
        InformationMaskingPatterns: {
            Values: []
        }
    },
    System: {
        UpdateClientDataFromChannel: true,
        ChannelOnDialogPanel: false,
        OperatorQuestionaryId: "",
        DialogSwitchNotificationWebhook: "",
        SwitchToBotWindowTitle: "",
        SwitchToBotWindowMessage: "",
        DisableMarkupModal: true,
        KnowledgeBaseConvertTemplate: {
            FileName: "",
            FileUrl: ""
        },
        EnableMessageSendBehaviour: false,
        AdditionalResendConfigDependsOnQueue: false,
        ColorSchema: {
            ColorSchemaUsing: false,
            ColorSchemaBaseColor: "#1066A8",
            ColorSchemaEditorColor: "#1066A8",
            ColorSchemaLinkColor: "#1066A8"
        },
        LogoHugeSizeChangeAllow: false,
        ColorSchemaChangeAllow: false,
        CustomMenus: {
            Values: []
        },
        CustomPermissions: {
            CustomPermissionsAllow: false,
            Values: []
        },
        AutoPinMessagesEnabled: false,
        AutoPinMessagesTypes: ""
    }
}
