export enum ReadyReportStatus {
    waiting,
    progress,
    ready,
    failed,
    export
}

export enum ReportFormat {
    xlsx = 1,
    csv = 2
}

export interface ReadyReport {
    ReadyReportId: string
    ReportId: string
    ReportTemplateConfigId?: string
    ReadyReportStatus: ReadyReportStatus
    Errors: string[]
    CreatedAt: number
    StartedAt?: number
    FinishedAt?: number
    DateTimeInterval: number
    CustomerId: string
    Params: Record<string, string>
    ReportFormat: ReportFormat
    TimeZone?: string
    Url?: string
    ExternalUrl?: string
    EmailRecipients?: string
    Progress: number
}

export interface ListReadyReportsByReportIdResponse {
    ReadyReports: ReadyReport[]
}

export interface ListMassSendingReportsRequest {
    Count: number
    SendingJobId?: string
}
