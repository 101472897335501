import {
    ModifyConfiguration,
    Supervisor,
    ViewClient,
    ViewDialogsSearch,
    ViewNewKnowledgeBase,
    ViewOldKnowledgeBase,
    ViewNewOperatorUI,
    ViewQueues,
    ViewStat,
    ViewMassMailing
} from "../../permissions"
import { MenuItem } from "../../models/menu"
import { isPermissionExists } from "../common/usePermissionsCheck"
import { MASS_MAILING_ENABLED } from "../common/flags"

type FrameMenuItems = {
    DialogsOld: MenuItem
    KnowledgeBaseOld: MenuItem
    History: MenuItem
    Stats: MenuItem
}
type MenuItems = {
    Dialogs: MenuItem
    KnowledgeBase: MenuItem
    Queues: MenuItem
    Profile: MenuItem
    NotificationList: MenuItem
    LogOut: MenuItem
    Settings: MenuItem
    UserManagement: MenuItem
    MassMailing: MenuItem
}
type DefinedMenuItems = FrameMenuItems & MenuItems
const frameMenuItems: FrameMenuItems = {
    DialogsOld: {
        id: "dialogs-old",
        title: "menu-item-dialogs",
        role: ViewClient,
        submenus: []
    },
    KnowledgeBaseOld: {
        id: "knowledge-base-old",
        title: "menu-item-knowledge-base-old",
        role: [Supervisor, ViewOldKnowledgeBase],
        submenus: [],
        rolesValidator: (roles, currentPermissions) => {
            /*
              Идея такая - если пользователь супервизор, то без разницы, какие у него права -
              мы всегда покажем для него этот пункт меню,
              ну а если нет, то пойдем по стандартному пути и будем проверять все остальные права
            */
            if (isPermissionExists(Supervisor, currentPermissions)) {
                return true
            }

            return roles.filter(role => role !== Supervisor).every(role => isPermissionExists(role, currentPermissions))
        }
    },
    History: {
        id: "history",
        title: "menu-item-dialog-history",
        role: ViewDialogsSearch,
        submenus: []
    },
    Stats: {
        id: "stats",
        title: "menu-item-new-stats",
        role: ViewStat,
        submenus: []
    }
}
const menuItems: MenuItems = {
    Dialogs: {
        id: "dialogs",
        title: "menu-item-dialogs",
        role: [ViewClient, ViewNewOperatorUI],
        submenus: []
    },
    KnowledgeBase: {
        id: "knowledge-base",
        title: "menu-item-knowledge-base",
        role: ViewNewKnowledgeBase,
        submenus: []
    },
    Queues: {
        id: "queues",
        title: "menu-item-queues",
        role: ViewQueues,
        submenus: []
    },
    Profile: {
        id: "user",
        title: `menu-item-profile`,
        submenus: []
    },
    NotificationList: {
        id: "notification-list",
        title: "menu-item-notification-list",
        submenus: []
    },
    LogOut: {
        id: "log-out",
        title: `menu-item-log-out`,
        submenus: []
    },
    Settings: {
        id: "project-settings",
        title: "menu-item-project-settings",
        role: ModifyConfiguration,
        submenus: []
    },
    UserManagement: {
        id: "user-management",
        title: "menu-item-user-management",
        role: Supervisor,
        submenus: []
    },
    MassMailing: {
        id: "mass-mailing",
        title: "menu-item-mass-mailing",
        role: ViewMassMailing,
        reference: MASS_MAILING_ENABLED,
        submenus: []
    }
}
export const definedMenuItems: DefinedMenuItems = {
    ...frameMenuItems,
    ...menuItems
}

export const knownAllMenuIds = Object.keys(definedMenuItems).map(k => {
    const kn = k as keyof typeof definedMenuItems
    return definedMenuItems[kn].id
})

export const menuIds = Object.keys(menuItems).map(k => {
    const kn = k as keyof typeof menuItems
    return menuItems[kn].id
})
