import { faCalendar } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { useField } from "formik"
import { Form, FormControl } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useModal } from "../../utility/common/useModal"
import { testId } from "../../utility/tests/testId"
import CalendarModal, { PickerProps } from "../CalendarModal/CalendarModal"
import CloseButton from "../CloseButton/CloseButton"
import styles from "./DatePickerInput.module.scss"

export interface DatePickerInputProps extends PickerProps {
    disabled?: boolean
    name: string
    error?: string
    label?: string
    valueToDisplay: string
    onClear: () => void
    onChange: (dates: Date[]) => void
}

export const DatePickerInput = (props: DatePickerInputProps) => {
    const {
        label,
        name,
        onChange,
        onClear,
        valueToDisplay,
        selectsRange = false,
        showTimeInput = false,
        minDate,
        disabled
    } = props
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <CalendarModal
            show={modalOpen}
            onClose={closeModal}
            onSubmit={onChange}
            onExited={onExited}
            minDate={minDate}
            selectsRange={selectsRange}
            showTimeInput={showTimeInput}
        />
    ))
    const { t } = useTranslation()
    const [, meta] = useField<string>(name)

    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <div className={cn(styles.picker, disabled && styles.picker_disabled)}>
                <FontAwesomeIcon icon={faCalendar} className={styles.picker__icon} />
                <FormControl
                    name={name}
                    key={valueToDisplay}
                    defaultValue={valueToDisplay}
                    disabled={disabled}
                    onClick={disabled ? undefined : openModal}
                    className={styles.picker__input}
                    isInvalid={!!meta.error}
                    data-testid={testId.datePickerInput}
                />
                {!disabled && (
                    <CloseButton
                        onClick={onClear}
                        className={cn(styles.picker__close, !!meta.error && styles.picker__close_error)}
                    />
                )}
                <Form.Control.Feedback type="invalid">{meta.error && t(meta.error)}</Form.Control.Feedback>
            </div>
        </>
    )
}
