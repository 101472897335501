import { push } from "connected-react-router"
import { capitalize } from "lodash-es"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Route, Switch } from "react-router-dom"
import { massMailingHub } from "../../api/instances"
import AddButton from "../../components/AddButton/AddButton"
import Can from "../../components/Can/Can"
import FormAccordion from "../../components/FormAccordion/FormAccordion"
import FullscreenLoader from "../../components/FullscreenLoader/FullscreenLoader"
import PageLayout from "../../components/PageLayout/PageLayout"
import {
    MassMailingGroup as MassMailingGroupType,
    MassMailingGroups,
    SendingJob,
    SendingJobRunStatus
} from "../../models/massMailing"
import { ModifyMassMailing } from "../../permissions"
import { selectMassMailings } from "../../store/massMailing/selectors"
import { selectCurrentProjectId, selectReferences } from "../../store/projects/selectors"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { logError } from "../../utility/common/logError"
import { testId } from "../../utility/tests/testId"
import styles from "./MassMailing.module.scss"
import { MassMailingEmptyView } from "./components/MassMailingEmptyView/MassMailingEmptyView"
import { MassMailingForm } from "./components/MassMailingForm/MassMailingForm"
import { MassMailingList } from "./components/MassMailingList/MassMailingList"
import { MassMailingListItemEmptyView } from "./components/MassMailingListItemEmptyView/MassMailingListItemEmptyView"
import { MassMailingSearchInput } from "./components/MassMailingSearchInput/MassMailingSearchInput"
import { MassMailingView } from "./components/MassMailingView/MassMailingView"

const tNamespace = "massMailing:"

const MassMailingGroup = ({ items, name }: MassMailingGroupType) => {
    const { t } = useTranslation()
    const testIdKey = `massMailing${capitalize(name)}List`

    if (testIdKey in testId) {
        return (
            <FormAccordion
                title={t(`${tNamespace}groups.${name}`)}
                initialOpened={true}
                headerClassName={styles.sidebar__section}
                data-testid={testId[testIdKey as keyof typeof testId]}
            >
                {items.length ? <MassMailingList items={items} /> : <MassMailingListItemEmptyView group={name} />}
            </FormAccordion>
        )
    } else {
        logError(`Invalid testIdKey: ${testIdKey}`)
        return null
    }
}

export const MassMailing = () => {
    const refs = useAppSelector(selectReferences)
    const dispatch = useAppDispatch()
    const projectId = useAppSelector(selectCurrentProjectId)
    const massMailings = useAppSelector(selectMassMailings)
    const groups = useMemo(() => {
        const activeGroup: SendingJob[] = []
        const finishedGroup: SendingJob[] = []

        massMailings.forEach(mailing => {
            if (mailing.Deleted || mailing.State.RunStatus === SendingJobRunStatus.Completed) {
                finishedGroup.push(mailing)
            } else {
                activeGroup.push(mailing)
            }
        })

        return [
            { name: MassMailingGroups.active, items: activeGroup },
            { name: MassMailingGroups.finished, items: finishedGroup.reverse() }
        ]
    }, [massMailings])

    useEffect(() => {
        if (projectId) {
            massMailingHub.subscribe(projectId)
        }

        return () => {
            if (projectId) {
                massMailingHub.unsubscribe(projectId)
            }
        }
    }, [projectId])

    const pagePath = `/project/${projectId}/mass-mailing`

    if (!refs) {
        return <FullscreenLoader hideLogo />
    }

    return (
        <PageLayout isSidebarCollapsed={true}>
            <PageLayout.LeftSidebar
                title=""
                className={styles.sidebar}
                headerClassName={styles.sidebar__header}
                isScrollable
                intermediate={
                    <div className={styles.sidebar__intermediate}>
                        <MassMailingSearchInput />
                        <Can permission={ModifyMassMailing}>
                            <AddButton
                                key="addMassMailing"
                                variant="outline-light"
                                data-testid={testId.openMassMailingForm}
                                onClick={() => dispatch(push(`/project/${projectId}/mass-mailing/create`))}
                            />
                        </Can>
                    </div>
                }
            >
                {groups.map(group => (
                    <MassMailingGroup key={group.name} {...group} />
                ))}
            </PageLayout.LeftSidebar>

            <PageLayout.Content className={styles.content}>
                <Switch>
                    <Route path={pagePath} exact>
                        <MassMailingEmptyView />
                    </Route>
                    <Route path={`${pagePath}/create`} exact>
                        <MassMailingForm />
                    </Route>
                    <Route path={`${pagePath}/:id`}>
                        {({ match }) => match && <MassMailingView id={match?.params.id} />}
                    </Route>
                </Switch>
            </PageLayout.Content>
        </PageLayout>
    )
}
