import React, { memo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { formTranslation } from "../../locales/form"
import { SlotType } from "../../models/slot"
import { GlobalUserSurveySlot } from "../../models/globalUserSurvey"
import styles from "./GlobalUserSurveyField.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DisplayTypeValue } from "../../models/survey"
import Linkify from "linkify-react"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import copy from "copy-to-clipboard"
import { Button } from "react-bootstrap"
import { faCopy } from "@fortawesome/pro-light-svg-icons"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { useTooltipWithTimeout } from "../../utility/common/useTooltipWithTimeout"
import CopyInput from "../CopyInput/CopyInput"
import unescape from "lodash/unescape"

const tNamespace = "dialogs:"

export enum GlobalUserSurveyFieldActionNames {
    undo = "undo",
    accept = "accept"
}

type GlobalUserSurveyFieldActionProps = {
    name: GlobalUserSurveyFieldActionNames
    icon: IconProp
    onClick?: (slot: GlobalUserSurveySlot) => void
    className?: string
}

export interface GlobalUserSurveyFieldProps {
    slot: GlobalUserSurveySlot
    slotValue: string
    name: string
    id: string
    containerClassName?: string
    actions?: GlobalUserSurveyFieldActionProps[]
    actionsContainerClassName?: string
    titleClassName?: string
    contentClassName?: string
    disabled?: boolean
}

const GlobalUserSurveyField = ({
    slot,
    name,
    id,
    slotValue,
    containerClassName,
    actions,
    actionsContainerClassName,
    titleClassName,
    contentClassName,
    disabled
}: GlobalUserSurveyFieldProps) => {
    const { t } = useTranslation()
    const [isEditing, setEditing] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)

    const handleClickStaticField = () => {
        setEditing(() => true)
    }

    const onClickAction = (actionCallback?: (slot: GlobalUserSurveySlot) => void) => {
        setEditing(() => false)

        if (actionCallback) {
            actionCallback(slot)
        }
    }

    const [show, tooltipLabel, setTooltipLabel] = useTooltipWithTimeout()

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        copy(slotValue)
        setTooltipLabel(t(formTranslation.copied))
    }

    const getCopyButton = (): JSX.Element => (
        <TooltipTrigger id={id} condition={show} placement="bottom" content={tooltipLabel} show={show}>
            <Button as="div" className={styles.questionerSlot__copyButton} variant="light" onClick={handleClick}>
                <FontAwesomeIcon icon={faCopy} />
            </Button>
        </TooltipTrigger>
    )

    // Сделано в рамках задачи: https://youtrack.craft-talk.ru/issue/MONIKI-119
    const slotValueUnescaped = unescape(slotValue)

    const isLink = slot.DisplayType === DisplayTypeValue.Link
    const isLongSlotValue = slotValue ? slotValue.length > 17 : false

    const renderFieldInner = () => {
        switch (slot.Type) {
            case SlotType.Dictionary:
                return (
                    <div className={styles.questionerSlot__inputField_typeSelect}>
                        <ValidatableInput
                            id={id}
                            name={name}
                            as="select"
                            type="text"
                            disabled={disabled || !slot.Domain.length}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {slot.Domain.map(option => (
                                <option value={option.Id} key={option.Id}>
                                    {unescape(option.Title)}
                                </option>
                            ))}
                        </ValidatableInput>
                    </div>
                )
            default:
                return (
                    <>
                        {isLink ? (
                            <TooltipTrigger
                                id={`slot-value-${id}`}
                                condition={isLongSlotValue}
                                placement="top"
                                content={slotValueUnescaped}
                                delay={500}
                            >
                                <div className={styles.questionerSlot__inputLinkField}>
                                    <CopyInput
                                        id={id}
                                        value={slotValueUnescaped}
                                        readOnly={false}
                                        placeholder={t(`${tNamespace}client-forms.input-link`)}
                                    />
                                </div>
                            </TooltipTrigger>
                        ) : (
                            <div className={styles.questionerSlot__inputField}>
                                {slot.IncreasedSize ? (
                                    <DebouncedValidatableInput
                                        id={id}
                                        as="textarea"
                                        name={name}
                                        className={styles.questionerSlot__inputField__increasedFieldSize}
                                        disabled={disabled}
                                        placeholder={slot.Title}
                                    />
                                ) : (
                                    <ValidatableInput
                                        id={id}
                                        name={name}
                                        type="text"
                                        disabled={disabled}
                                        placeholder={slot.Title}
                                    />
                                )}
                            </div>
                        )}
                    </>
                )
        }
    }

    const isDictionary = slot.Type === SlotType.Dictionary
    const isEmptySlot = slotValue.length === 0

    const getInitialValue = () => {
        const displayLinkCondition = isLink ? (
            <Linkify options={{ target: "_blank" }}>{slotValueUnescaped}</Linkify>
        ) : (
            slotValueUnescaped
        )

        return isDictionary ? unescape(slot.Domain.find(d => d.Id === slotValue)?.Title || "") : displayLinkCondition
    }

    return (
        <div
            className={cn(styles.questionerSlot__section, containerClassName)}
            onClick={!isEditing && !slot.ReadOnly ? handleClickStaticField : undefined}
            ref={containerRef}
        >
            {!slot.ReadOnly ? (
                <>
                    <label className={cn(styles.questionerSlot__title, titleClassName)} htmlFor={id}>
                        {slot.Title}
                    </label>
                    <div className={styles.questionerSlot__section}>
                        {renderFieldInner()}
                        {!disabled && Array.isArray(actions) && (
                            <div
                                className={cn(
                                    styles.questionerSlot__actionsContainer,
                                    !isEditing && styles.questionerSlot__actionsContainer_noEdited,
                                    isDictionary && styles.questionerSlot__dictionaryActionsContainer,
                                    actionsContainerClassName
                                )}
                            >
                                {actions.map(({ name, icon, onClick, className }) => {
                                    return (
                                        <button
                                            key={name}
                                            onClick={() => onClickAction(onClick)}
                                            className={cn(styles.questionerSlot__actionsButton, className)}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </button>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className={cn(styles.questionerSlot__title, titleClassName)}>{slot.Title}</div>
                    <div className={styles.questionerSlot__section}>
                        <TooltipTrigger
                            id={`slot-value-${id}`}
                            condition={isLink && isLongSlotValue}
                            placement="top"
                            content={slotValueUnescaped}
                        >
                            <div
                                className={cn(
                                    contentClassName,
                                    styles.questionerSlot__content,
                                    isLink
                                        ? styles.questionerSlot__content__readonlyLink
                                        : styles.questionerSlot__content_readonly
                                )}
                            >
                                {getInitialValue()}
                            </div>
                        </TooltipTrigger>
                        {isLink && !isEmptySlot && getCopyButton()}
                    </div>
                </>
            )}
        </div>
    )
}

export default memo(GlobalUserSurveyField)
