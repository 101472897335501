import React, { ReactNode, useCallback } from "react"
import { Image } from "react-bootstrap"
import cn from "classnames"
import styles from "./UserCard.module.scss"
import { ClassProps } from "../../utility/common/props"
import { testId } from "../../utility/tests/testId"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { useTranslation } from "react-i18next"
import { colorByName } from "../../helpers/color"
import { generateFullName } from "../../utility/common/generateFullName"

const tNamespace = "dialogs:info."

export interface UserCardProps extends ClassProps {
    firstName?: string
    lastName?: string
    middleName?: string
    picture?: string
    isShort?: boolean
    onClick?: () => void
    indicator?: ReactNode
    userCardMaxLength?: number
    isClient?: boolean
    isNewWorkplace?: boolean
    inactive?: boolean
    inHeader?: boolean
}

const tooltipId = "user-card-tooltip"
const tooltipDelay = { show: 450, hide: 0 }

const UserCard: React.FC<UserCardProps> = props => {
    const {
        firstName,
        lastName,
        middleName,
        picture,
        isShort,
        onClick,
        indicator,
        userCardMaxLength = 15,
        isClient,
        isNewWorkplace,
        inactive,
        inHeader
    } = props
    const { t } = useTranslation()
    const fullUserName = `${firstName} ${lastName}`

    const getLetter = useCallback(
        (type: "firstName" | "lastName"): string => {
            const typeValue = props[type]

            if (typeValue && typeof typeValue === "string" && typeValue.length > 0) {
                return typeValue[0]
            }

            return ""
        },
        [props]
    )

    const unknownClientFullName = `${t(`${tNamespace}unknown-client-firstname`)} ${t(
        `${tNamespace}unknown-client-lastname`
    )}`

    const fullName = generateFullName(firstName, lastName, middleName, isClient ? unknownClientFullName : "")

    const getLetters = useCallback(() => {
        let resultStr = ""

        if (lastName && lastName.length) {
            resultStr += lastName[0]
        }

        if (firstName && firstName.length) {
            resultStr += firstName[0]
        }

        if (resultStr.length < 1 && middleName && middleName.length) {
            resultStr += middleName[0]
        }

        if (resultStr.length || !isClient) {
            return resultStr
        }

        return t(`${tNamespace}unknown-client-firstname`)[0] + t(`${tNamespace}unknown-client-lastname`)[0]
    }, [firstName, lastName, middleName, isClient, t])

    const renderUserCardInfo = () => {
        if (isShort) {
            return null
        }

        return (
            <div className={styles.userCard__info}>
                <TooltipTrigger
                    id={tooltipId}
                    placement="top"
                    content={fullName}
                    condition={!inHeader && fullName.length > userCardMaxLength}
                    delay={tooltipDelay}
                >
                    {isNewWorkplace ? (
                        <div className={styles.userCard__clientFullName}>{fullName}</div>
                    ) : (
                        <div className={styles.userCard__introduction}>
                            <div className={styles.userCard__firstName}>{lastName}</div>
                            <div className={styles.userCard__lastName}>{firstName}</div>
                        </div>
                    )}
                </TooltipTrigger>
                {props.children}
            </div>
        )
    }

    return (
        <div
            className={cn(
                styles.userCard,
                props.className,
                isShort && styles.userCard_short,
                onClick && styles.userCard_clickable
            )}
            onClick={onClick}
            data-test-id={testId.userCard}
        >
            <div className={styles.userCard__pictureContainer}>
                {picture ? (
                    <Image
                        className={cn(
                            inHeader
                                ? styles.userCard__picture_small
                                : isShort
                                ? styles.userCard__picture_short
                                : styles.userCard__picture_normal,
                            inactive && styles.userCard__picture_inactive
                        )}
                        src={picture}
                    />
                ) : (
                    <div
                        className={cn(
                            inHeader
                                ? styles.userCard__picture_small
                                : isShort
                                ? styles.userCard__picture_short
                                : styles.userCard__picture_normal,
                            styles.userCard__picture_default
                        )}
                        style={{ backgroundColor: !inactive ? colorByName(fullName) : "#E0E0E0" }}
                    >
                        {getLetters()}
                    </div>
                )}
                {indicator && <div className={styles.userCard__indicator}>{indicator}</div>}
            </div>
            {renderUserCardInfo()}
        </div>
    )
}

export default UserCard
