import {
    AgentProjectSettings,
    AutoGreetingOperatorProjectSettings,
    ClientSurveyOperatorProjectSettings,
    CommonOperatorProjectSettings,
    CommonReportSlotsValue,
    CustomSectionsValue,
    DialogSurveyOperatorProjectSettings,
    NotificationsOperatorProjectSettings,
    OutgoingDialogOperatorProjectSettings,
    ProjectSettingsListValue,
    ResponseTemplateOperatorProjectSettings,
    SecurityProjectSettings,
    SendMessageButtonsValues,
    StatisticsCalculatingOptions,
    StatisticsPeriodOptions,
    SystemProjectSettings,
    WorkplaceOperatorProjectSettings
} from "./projectSettings"
import { DeepRequired } from "../types/utilityTypes"

export interface OperatorsWorkValuesBase {
    Common: CommonOperatorProjectSettings
    ClientSurvey: ClientSurveyOperatorProjectSettings
    DialogSurvey: DialogSurveyOperatorProjectSettingsValues
    NewWorkplace: NewWorkplaceOperatorProjectSettings
    KPI: KPIOperatorProjectSettingsValues
    Workplace: WorkplaceOperatorProjectSettings
    AutoGreeting: AutoGreetingOperatorProjectSettings
    ResponseTemplate: ResponseTemplateOperatorProjectSettingsValues
    Hold: HoldOperatorProjectSettingsValues
    Notifications: NotificationsOperatorProjectSettings
    OutgoingDialog: OutgoingDialogOperatorProjectSettings
}

export type OperatorsWorkValues = DeepRequired<OperatorsWorkValuesBase>

export enum ChooseTopicToFinishDialogType {
    Required = "required",
    Automatic = "automatic"
}

export interface DialogSurveyOperatorProjectSettingsValues
    extends Omit<DeepRequired<DialogSurveyOperatorProjectSettings>, "ChooseTopicToFinishDialogRequired" | ""> {
    ChooseTopicToFinishDialog: ChooseTopicToFinishDialogType
}

export interface ResponseTemplateOperatorProjectSettingsValues
    extends Omit<ResponseTemplateOperatorProjectSettings, "WaitingForClientResponseTimeout" | "ClientHoldingTimeout"> {
    WaitingForClientResponseTimeout: DurationSettingsValues
    ClientHoldingTimeout: DurationSettingsValues
}

export interface HoldOperatorProjectSettingsValues {
    AutoHoldingEnabled: boolean
    DropDialogTimeout: DurationSettingsValues
    FinishDialogWithoutClientResponseTimeout: DurationSettingsValues
}

// System project settings
export interface SystemSettingsValues {
    Security: DeepRequired<SecurityProjectSettings>
    System: DeepRequired<SystemProjectSettings>
}

// Dialog settings
export interface DialogSettingsValues {
    DialogTimeout: DialogTimeoutProjectSettingsValues
    Agent: DeepRequired<AgentProjectSettings>
}

export interface DialogTimeoutProjectSettingsValues {
    FirstTimeout: TimeoutSettingsValues
    SecondTimeout: TimeoutSettingsValues
    FinishDialogTimeout: TimeoutSettingsValues
    DialogInQueueTimeout: TimeoutSettingsValues
    DialogLifeTimeout: TimeoutSettingsValues
}

export interface TimeoutSettingsValues {
    Value: DurationSettingsValues
    Message: string
    Enabled: boolean
}

export interface DurationSettingsValues {
    Days: number
    Hours: number
    Minutes: number
    Seconds: number
}

export interface StatisticsAndReportSettingsValues {
    Statistics: DeepRequired<StatisticsProjectSettingsValues>
    Report: DeepRequired<ReportProjectSettingsValues>
}

export interface StatisticsProjectSettingsValues {
    FunnelSlots: ProjectSettingsListValue
    UnproductiveAnswers: ProjectSettingsListValue
    TimeRangeStartDefiningField: StatisticsPeriodOptions
    TimeRangeEndDefiningField: StatisticsPeriodOptions
    HandledCountField: StatisticsPeriodOptions
    ExcludedOperatorIds: ProjectSettingsListValue
    FCR: DurationSettingsValues
    FCRSeparateDifferentTopics: boolean
    SLEndDefiningField: StatisticsCalculatingOptions
    SLPeriod: DurationSettingsValues
    AHTStartDefiningField: StatisticsCalculatingOptions
}

export interface ReportProjectSettingsValues {
    CommonReportTimeZone: string
    CommonReportSlots: CommonReportSlotsValue
}

export interface NewWorkplaceOperatorProjectSettings {
    CustomSections: CustomSectionsValue
    ClientsSectionName: string
    DialogSurveyId: string
    ClientSurveyId: string
    EnableSeparateNewAndInProgressDialogs: boolean
    SendMessageActionsAsButtons: SendMessageButtonsValues
}

export interface KPIOperatorProjectSettingsValues {
    DialogTimerEnabled: boolean
    GreenDialogTimerTimeout: DurationSettingsValues
    YellowDialogTimerTimeout: DurationSettingsValues
    DialogWaitingForClientAnswerTimerEnabled: boolean
    DialogWaitingForClientAnswerTimeout: DurationSettingsValues
    DialogWaitingForOperatorAnswerTimerEnabled: boolean
    DialogWaitingForOperatorAnswerTimeout: DurationSettingsValues
    DialogTimerTimeoutPlusEnabled: boolean
    DialogTimerTimeoutCustomLabelEnabled: boolean
    DialogTimerTimeoutCustomLabelEnabledName: string
}

export enum BandwidthSetting {
    DefaultSetting = "DefaultSetting",
    Limits = "Limits"
}

export type BandwidthLimit = {
    [group: string]: number
}

export type BandwidthValues = {
    bandwidthSetting: BandwidthSetting
    bandwidthLimits?: BandwidthLimit
}

export type Bandwidth = {
    BandwidthSetting: BandwidthSetting
    BandwidthLimits?: BandwidthLimit
}
