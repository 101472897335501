import { RootState } from "../rootReducer"
import { createSelector } from "@reduxjs/toolkit"

export const selectCurrentBranch = (state: RootState) => state.knowledgeBase.currentBranch
export const selectKnowledgeBaseWorkMode = (state: RootState) => state.knowledgeBase.workMode.knowledgeBase
export const selectArticleWorkMode = (state: RootState) => state.knowledgeBase.workMode.article
export const selectCurrentArticleState = (state: RootState) => state.knowledgeBase.currentArticle
export const selectCurrentArticle = (state: RootState) => state.knowledgeBase.currentArticle?.data
export const selectSearchArticlesState = (state: RootState) => state.knowledgeBase.searchArticles
export const selectUpdateArticleState = (state: RootState) => state.knowledgeBase.updateArticle
export const selectShareArticleState = (state: RootState) => state.knowledgeBase.shareArticle
export const selectRemoveArticleSharingState = (state: RootState) => state.knowledgeBase.removeArticleSharing
export const selectCatalogsState = (state: RootState) => state.knowledgeBase.getCatalogs
export const selectCatalogsMap = (state: RootState) => state.knowledgeBase.catalogsMap
export const selectCatalog = (state: RootState, id: string) => state.knowledgeBase.catalogsMap[id]
export const selectCurrentCatalogCode = (state: RootState) => state.knowledgeBase.currentBranch.CurrentCatalogCode
export const selectCurrentCatalog = createSelector(selectCurrentCatalogCode, selectCatalogsMap, (code, catalogsMap) =>
    code ? catalogsMap[code] : undefined
)
export const selectCurrentCatalogPermittedAction = createSelector(selectCurrentCatalog, catalog =>
    catalog ? catalog.items[catalog.rootId].data.permittedAction : undefined
)
export const selectRelatedCategoriesState = (state: RootState) => state.knowledgeBase.relatedCategories
export const selectCreateCategoryState = (state: RootState) => state.knowledgeBase.createCategory
export const selectMoveCategoryState = (state: RootState) => state.knowledgeBase.moveCategory
export const selectCreateCatalogState = (state: RootState) => state.knowledgeBase.createCatalog
export const selectUpdateCatalogState = (state: RootState) => state.knowledgeBase.updateCatalog
export const selectDeleteCatalogState = (state: RootState) => state.knowledgeBase.deleteCatalog
export const selectShareCatalogState = (state: RootState) => state.knowledgeBase.shareCatalog
export const selectGetArticleTypesState = (state: RootState) => state.knowledgeBase.articleTypes
export const selectCreateArticleTypeState = (state: RootState) => state.knowledgeBase.createArticleType
export const selectUpdateArticleTypeState = (state: RootState) => state.knowledgeBase.updateArticleType
export const selectArticleTypes = (state: RootState) => state.knowledgeBase.articleTypes.data
export const selectCurrentArticlesFilter = (state: RootState) => state.knowledgeBase.currentArticlesFilter
export const selectArticleUserViewsState = (state: RootState) => state.knowledgeBase.articleUserViews
export const selectCurrentAnswer = (state: RootState) => state.knowledgeBase.currentAnswer
export const selectCurrentAnswerId = (state: RootState) => state.knowledgeBase.currentAnswer?.Id
export const selectAnswersFrom = (state: RootState) => state.knowledgeBase.answersFrom
export const selectArticleFormQuestions = (state: RootState) => state.knowledgeBase.formQuestions
export const selectArticleMarkedMessages = (state: RootState) => state.knowledgeBase.markedMessages
export const selectArticleMarkedMessagesCount = (state: RootState) => state.knowledgeBase.markedMessagesCount
export const selectArticleMarkedMessagesRemovedIds = (state: RootState) =>
    state.knowledgeBase.markedMessagesReserve.RemovedIds
export const selectSharedTypeState = (state: RootState) => state.knowledgeBase.sharedType
export const selectGetDefaultArticleState = (state: RootState) => state.knowledgeBase.defaultArticle
export const selectIsGetDefaultArticleCompleted = createSelector(
    selectGetDefaultArticleState,
    state => !(state.inProcess || state.data === undefined)
)
export const selectDefaultArticle = (state: RootState) => state.knowledgeBase.defaultArticle.data
export const selectEditedArticleExtId = (state: RootState) => state.knowledgeBase.editedArticleExtId
export const selectEditedArticleSurvey = (state: RootState) => state.knowledgeBase.editedArticleSurvey
export const selectArticleExpanded = (state: RootState) => state.knowledgeBase.articleExpanded
export const selectArticleFavorite = (state: RootState) => state.knowledgeBase.articleFavorite
export const selectFavoriteArticlesBlock = (state: RootState) => state.knowledgeBase.articleFavoriteBlock
export const selectFavoriteArticlesBlockExpanded = (state: RootState) =>
    state.knowledgeBase.articleFavoriteBlockExpanded
export const selectMoveFavoriteArticle = (state: RootState) => state.knowledgeBase.moveFavoriteArticle
export const selectArticleActionButtonsActions = (state: RootState) => state.knowledgeBase.articleActionButtonsActions
