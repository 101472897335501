import { selectProjectSuccess, SelectProjectSuccessPayload } from "./actions"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { Project } from "../../models/project"
import { ProjectCopyWizardFormValues, ProjectWizard, ProjectWizardFormValues } from "../../models/projectWizard"
import { ProjectUser } from "../../models/projectUser"
import { Reference } from "../../models/reference"
import {
    GetSettingsWithProjectResponse,
    ProjectSettings,
    BandwidthGroupsFromRef,
    BandwidthGroup
} from "../../models/projectSettings"
import { updateNestedSettings } from "../../utility/common/updateNestedSettings"
import { resetReducerState } from "../action"

export type ProjectState = Readonly<{
    projects: AsyncState<Project[]>
    defaultProjectSettings: AsyncState<GetSettingsWithProjectResponse>
    projectStillCreating: AsyncState<boolean>
    themeInititalizing: AsyncState<boolean>
    nonSelectedProjects?: Project[]
    selectProject: AsyncState<Project>
    wizard: ProjectWizard
    wizardFormValues?: ProjectWizardFormValues
    createProject: AsyncState<string>
    inviteUsers: AsyncState<void>
    updateProject: AsyncState<Project>
    updateLogo: AsyncState<Project>
    frameLoading: AsyncState<void>
    frameForceUnloaded: boolean
    frameActive: boolean
    blockUser: AsyncState<void>
    uploadProject: AsyncState<string>
    copyWizard: ProjectWizard
    copyWizardFormValues?: ProjectCopyWizardFormValues
    createProjectCopy: AsyncState<string>
    references: AsyncState<Reference[]>
    settings: ProjectSettings | null
    getSettings: AsyncState<void>
    updateSettings: AsyncState<void>
    bandwidthGroups: AsyncState<BandwidthGroup[]>
    bandwidthDefaultGroup: string
}>

const defaultProjectWizard: ProjectWizard = {
    ready: false,
    stage: 0
}

const initialState: ProjectState = {
    projects: AsyncState.create(),
    defaultProjectSettings: AsyncState.create(),
    projectStillCreating: AsyncState.create(),
    themeInititalizing: AsyncState.create(),
    selectProject: AsyncState.create(),
    wizard: defaultProjectWizard,
    createProject: AsyncState.create(),
    inviteUsers: AsyncState.create(),
    updateProject: AsyncState.create(),
    updateLogo: AsyncState.create(),
    frameLoading: AsyncState.create(),
    frameActive: false,
    frameForceUnloaded: false,
    blockUser: AsyncState.create(),
    uploadProject: AsyncState.create(),
    copyWizard: defaultProjectWizard,
    createProjectCopy: AsyncState.create(),
    references: AsyncState.create(),
    settings: null,
    getSettings: AsyncState.create(),
    updateSettings: AsyncState.create(),
    bandwidthGroups: AsyncState.create(),
    bandwidthDefaultGroup: ""
}

const projects = createSlice({
    name: "projects",
    initialState,
    reducers: {
        getProjectsProcess(state) {
            state.projects = state.projects.toProcess()
        },
        getProjectsSuccess(state, action: PayloadAction<Project[]>) {
            state.projects = state.projects.toSuccess(action.payload)
        },
        getProjectsFailed(state, action: PayloadAction<SystemError>) {
            state.projects = state.projects.toFailed(action.payload)
        },
        getDefaultProjectSettingsProcess(state) {
            state.defaultProjectSettings = state.defaultProjectSettings.toProcess()
        },
        getDefaultProjectSettingsSuccess(state, action: PayloadAction<GetSettingsWithProjectResponse>) {
            state.defaultProjectSettings = state.defaultProjectSettings.toSuccess(action.payload)
        },
        getDefaultProjectSettingsFailed(state, action: PayloadAction<SystemError>) {
            state.defaultProjectSettings = state.defaultProjectSettings.toFailed(action.payload)
        },
        getProjectCreatingStatusProcess(state) {
            state.projectStillCreating = state.projectStillCreating.toProcess()
        },
        getProjectCreatingStatusSuccess(state, action: PayloadAction<boolean>) {
            state.projectStillCreating = state.projectStillCreating.toSuccess(action.payload)

            if (action.payload) {
                state.copyWizard.stage = 3
            }
        },
        getProjectCreatingStatusFailed(state, action: PayloadAction<SystemError>) {
            state.projectStillCreating = state.projectStillCreating.toFailed(action.payload)
        },
        getThemeInitializingStatusProcess(state) {
            state.themeInititalizing = state.themeInititalizing.toProcess()
        },
        getThemeInitializingStatusSuccess(state, action: PayloadAction<boolean>) {
            state.themeInititalizing = state.themeInititalizing.toSuccess(action.payload)
        },
        getThemeInitializingStatusFailed(state, action: PayloadAction<SystemError>) {
            state.themeInititalizing = state.themeInititalizing.toFailed(action.payload)
        },
        selectProjectProcess(state) {
            state.selectProject = state.selectProject.toProcess()
        },
        selectProjectFailed(state, action: PayloadAction<SystemError>) {
            state.selectProject = state.selectProject.toFailed(action.payload)
        },
        initWizard(state) {
            state.wizard = { ready: true, stage: 0 }
            state.wizardFormValues = undefined
            state.createProject = AsyncState.create()
        },
        resetWizard(state) {
            state.wizard = { ...defaultProjectWizard }
            state.wizardFormValues = undefined
            state.createProject = AsyncState.create()
        },
        nextWizardStage(state, action: PayloadAction<ProjectWizardFormValues>) {
            state.wizard.stage++
            state.wizardFormValues = action.payload
        },
        prevWizardStage(state) {
            state.wizard.stage--
        },
        createProjectProcess(state) {
            state.createProject = state.createProject.toProcess()
        },
        createProjectSuccess(state, action: PayloadAction<Project>) {
            state.wizard.projectId = action.payload.id
            state.wizard.stage++
            state.createProject = state.createProject.toSuccess(action.payload.id)
            state.projects = state.projects.map(v => [...v, action.payload])
        },
        createProjectFailed(state, action: PayloadAction<SystemError>) {
            state.createProject = state.createProject.toFailed(action.payload)
        },
        updateProjectProcess(state) {
            state.updateProject = state.updateProject.toProcess()
        },
        updateProjectSuccess(state, action: PayloadAction<Project>) {
            state.updateProject = state.updateProject.toSuccess(action.payload)
            state.projects = state.projects.map(v => v.map(p => (p.id === action.payload.id ? action.payload : p)))
            state.nonSelectedProjects = state.projects.map(v => v.filter(p => p.id !== action.payload.id)).data
            state.selectProject = state.selectProject.map(_ => action.payload)
        },
        updateProjectFailed(state, action: PayloadAction<SystemError>) {
            state.updateProject = state.updateProject.toFailed(action.payload)
        },
        inviteUsersProcess(state) {
            state.inviteUsers = state.inviteUsers.toProcess()
        },
        inviteUsersSuccess(state, _: PayloadAction<ProjectUser[]>) {
            state.inviteUsers = state.inviteUsers.toSuccess()
        },
        inviteUsersFailed(state, action: PayloadAction<SystemError>) {
            state.inviteUsers = state.inviteUsers.toFailed(action.payload)
        },
        activateFrame(state) {
            state.frameActive = true
        },
        frameLoadedProcess(state) {
            state.frameLoading = state.frameLoading.toProcess()
        },
        frameForceUnload(state) {
            state.frameLoading = state.frameLoading.toProcess()
            state.frameForceUnloaded = true
        },
        frameForceLoad(state) {
            state.frameForceUnloaded = false
        },
        frameLoadedSuccess(state) {
            state.frameLoading = state.frameLoading.toSuccess()
        },
        blockUserProcess(state) {
            state.blockUser = state.blockUser.toProcess()
        },
        blockUserSuccess(state, action: PayloadAction<string>) {
            state.blockUser = state.blockUser.toSuccess()
        },
        blockUserFailed(state, action: PayloadAction<SystemError>) {
            state.blockUser = state.blockUser.toFailed(action.payload)
        },
        uploadProjectProcess(state) {
            state.uploadProject = state.uploadProject.toProcess()
        },
        uploadProjectSuccess(state, action: PayloadAction<string>) {
            state.uploadProject = state.uploadProject.toSuccess(action.payload)
        },
        uploadProjectFailed(state, action: PayloadAction<SystemError>) {
            state.uploadProject = state.uploadProject.toFailed(action.payload)
        },
        initCopyWizard(state) {
            state.copyWizard = { ready: true, stage: 0 }
            state.copyWizardFormValues = undefined
            state.createProjectCopy = AsyncState.create()
        },
        resetCopyWizard(state) {
            state.copyWizard = { ...defaultProjectWizard }
            state.copyWizardFormValues = undefined
            state.createProjectCopy = AsyncState.create()
        },
        nextCopyWizardStage(state, action: PayloadAction<ProjectCopyWizardFormValues>) {
            state.copyWizard.stage++
            state.copyWizardFormValues = action.payload
        },
        prevCopyWizardStage(state) {
            state.copyWizard.stage--
        },
        setCopyWizardStage(state, action: PayloadAction<number>) {
            state.copyWizard.stage = action.payload
        },
        incrementalNextCopyWizardStage(state) {
            state.copyWizard.stage++
        },
        createProjectCopyProjectRequestSuccess(state) {
            state.createProjectCopy = state.createProjectCopy.toSuccess("")
        },
        createProjectCopyProcess(state) {
            state.createProjectCopy = state.createProjectCopy.toProcess()
        },
        createProjectCopySuccess(state, action: PayloadAction<Project>) {
            state.copyWizard.projectId = action.payload.id
            state.createProjectCopy = state.createProjectCopy.toSuccess(action.payload.id)
            state.projects = state.projects.map(v => [...v, action.payload])
        },
        createProjectCopyFailed(state, action: PayloadAction<SystemError>) {
            state.createProjectCopy = state.createProjectCopy.toFailed(action.payload)
        },
        getReferencesProcess(state) {
            state.references = state.references.toProcess()
        },
        getReferencesSuccess(state, action: PayloadAction<Reference[]>) {
            state.references = state.references.toSuccess(action.payload)
        },
        getReferencesFailed(state, action: PayloadAction<SystemError>) {
            state.references = state.references.toFailed(action.payload)
        },
        getProjectSettingsProcess(state) {
            state.getSettings = state.getSettings.toProcess()
        },
        getProjectSettingsSuccess(state, action: PayloadAction<ProjectSettings>) {
            state.getSettings = state.getSettings.toSuccess()
            state.settings = action.payload
        },
        getProjectSettingsFailed(state, action: PayloadAction<SystemError>) {
            state.getSettings = state.getSettings.toFailed(action.payload)
            state.settings = null
        },
        updateProjectSettingsProcess(state) {
            state.updateSettings = state.updateSettings.toProcess()
        },
        updateProjectSettingsSuccess(state, action: PayloadAction<ProjectSettings>) {
            state.updateSettings = state.updateSettings.toSuccess()

            Object.entries(action.payload).forEach(([key, value]) => {
                if (!state.settings) state.settings = {}

                const settingsKey = key as keyof ProjectSettings
                const currentValue = state.settings && state.settings[settingsKey]
                const updatedValue = currentValue && updateNestedSettings(currentValue, value)

                state.settings = {
                    ...state.settings,
                    [settingsKey]: updatedValue
                }
            })
        },
        updateProjectSettingsFailed(state, action: PayloadAction<SystemError>) {
            state.updateSettings = state.updateSettings.toFailed(action.payload)
        },
        getBandwidthGroupsProcess(state) {
            state.bandwidthGroups = state.bandwidthGroups.toProcess()
        },
        getBandwidthGroupsSuccess(state, action: PayloadAction<BandwidthGroupsFromRef>) {
            const groups = Object.entries(action.payload.Groups).map(([key, value]) => ({
                Id: key,
                Title: value
            }))

            state.bandwidthGroups = state.bandwidthGroups.toSuccess(groups)
            state.bandwidthDefaultGroup = action.payload.Default
        },
        getBandwidthGroupsFailed(state, action: PayloadAction<SystemError>) {
            state.bandwidthGroups = state.bandwidthGroups.toFailed(action.payload)
        }
    },

    extraReducers(builder) {
        builder
            .addCase(selectProjectSuccess, (state, action: PayloadAction<SelectProjectSuccessPayload>) => {
                state.nonSelectedProjects = state.projects.map(v =>
                    v.filter(p => p.id !== action.payload.project.id)
                ).data
                state.selectProject = state.selectProject.toSuccess(action.payload.project)
            })
            .addCase(resetReducerState, () => {
                return initialState
            })
    }
})

export default projects.reducer

export const actions = projects.actions
