import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { CellProps, Column, useGlobalFilter, useSortBy, useTable } from "react-table"
import SettingsTable from "../../../../../../components/SettingsTable/SettingsTable"
import TooltipTrigger from "../../../../../../components/TooltipTrigger/TooltipTrigger"
import { ChannelNames } from "../../../../../../models/channel"
import { MassMailingFormat, MassMailingItemUserStatuses, SendingJobItem } from "../../../../../../models/massMailing"
import { getMassMailingItemStatus } from "../../../../helpers"
import styles from "./MassMailingItemsList.module.scss"

export interface MassMailingItemsListProps {
    items: SendingJobItem[]
    omniChannelId?: string
    channelNames?: ChannelNames
}

type Status = {
    value: string
    status: MassMailingItemUserStatuses
    error?: string
}

const tNamespase = "massMailing:items."
const tStatusNamespase = "massMailing:items.statuses."

export const MassMailingItemsList = ({ items, omniChannelId, channelNames }: MassMailingItemsListProps) => {
    const format = omniChannelId ? MassMailingFormat.omni : MassMailingFormat.channel

    const { t } = useTranslation()

    const columns: Array<Column<SendingJobItem>> = useMemo(
        () => [
            {
                id: "channel-id",
                Header: t(`${tNamespase}id-${format}`).toString(),
                disableSortBy: true,
                accessor: (original): string | undefined => {
                    let id

                    if (original.State.SendingResults) {
                        const lastResult = original.State.SendingResults[original.State.SendingResults.length - 1]
                        id =
                            lastResult.SendMessageParams.OmniUserId ||
                            lastResult.SendMessageParams.DestinationChannel.ChannelUserId
                    } else if (original.Data.SendingCascade) {
                        id =
                            original.Data.SendingCascade.Variants[0].SendMessageParams?.DestinationChannel.ChannelUserId
                    } else if (original.Data.CascadeOmniUserParams) {
                        id = original.Data.CascadeOmniUserParams.OmniUserId
                    }

                    return id
                }
            },
            {
                id: "channel",
                Header: t(`${tNamespase}channel`).toString(),
                disableSortBy: true,
                accessor: (original): string | undefined => {
                    let channel

                    if (omniChannelId) {
                        channel = omniChannelId
                    } else if (original.State.SendingResults) {
                        const lastResult = original.State.SendingResults[original.State.SendingResults.length - 1]
                        channel = lastResult.SendMessageParams.DestinationChannel.ChannelId
                    } else if (original.Data.SendingCascade) {
                        channel =
                            original.Data.SendingCascade.Variants[0].SendMessageParams?.DestinationChannel.ChannelId
                    }

                    return channelNames && channel ? channelNames[channel] : channel
                }
            },
            {
                id: "status",
                Header: t(`${tNamespase}status`).toString(),
                disableSortBy: true,
                accessor: (original): Status => {
                    const status = getMassMailingItemStatus(original)
                    return {
                        value: t(`${tStatusNamespase}${status}`).toString(),
                        error: original.State.Errors && original.State.Errors.join("\n"),
                        status
                    }
                },
                Cell: ({ row: { values } }: CellProps<SendingJobItem>) => {
                    const status = (values as { status: Status }).status
                    const isError = status.status === MassMailingItemUserStatuses.error
                    return (
                        <>
                            {isError ? (
                                <TooltipTrigger id="mass-mailing-item-status" content={status.error}>
                                    <span className={styles.statusError}>{status.value}</span>
                                </TooltipTrigger>
                            ) : (
                                <>{status.value}</>
                            )}
                        </>
                    )
                }
            }
        ],
        [channelNames, omniChannelId]
    )

    const tableInstance = useTable({ columns, data: items }, useGlobalFilter, useSortBy)

    return (
        <div className={styles.massMailingItemsList}>
            <SettingsTable tableInstance={tableInstance} rows={tableInstance.rows} hideSearch />
        </div>
    )
}
