import { useCallback } from "react"
import AsyncSearchableInput, {
    AsyncSearchableInputProps
} from "../../../../components/AsyncSearchableInput/AsyncSearchableInput"
import { useAppDispatch } from "../../../../store/store"
import { searchChannel } from "./helpers"
import { testId } from "../../../../utility/tests/testId"

// TODO: 1.   Вынести этот компонент в общую папку components и написать stories
// TODO: 2.a  Объединить с имеющимся там "узким" ChennelsSelect
// TODO: 2.б  Засунуть "узкий" только в место использования

export interface ChannelSelectProps extends Omit<AsyncSearchableInputProps, "getOptions"> {
    projectId: string | undefined
}

export const ChannelSelect = (props: ChannelSelectProps) => {
    const { projectId } = props
    const dispatch = useAppDispatch()
    const getOptions: AsyncSearchableInputProps["getOptions"] = useCallback(
        async (input: string) => {
            return projectId ? searchChannel(dispatch, projectId, input) : []
        },
        [dispatch, projectId]
    )

    return <AsyncSearchableInput {...props} getOptions={getOptions} data-testid={testId.channelSelectInput} />
}
