import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { AllRoleSettings, Role } from "../../models/role"
import { resetReducerState } from "../action"

export type RolesState = Readonly<{
    roles: AsyncState<Role[]>
    allRoleSettings: AsyncState<AllRoleSettings>
    allGlobalPermissions: AsyncState<string[]>
    createRole: AsyncState<Role>
    updateRole: AsyncState<Role>
    updateManyRoles: AsyncState<Role[]>
    deleteRole: AsyncState<void>
}>

const initialState: RolesState = {
    roles: AsyncState.create(),
    allRoleSettings: AsyncState.create(),
    allGlobalPermissions: AsyncState.create(),
    createRole: AsyncState.create(),
    updateRole: AsyncState.create(),
    updateManyRoles: AsyncState.create(),
    deleteRole: AsyncState.create()
}

const roles = createSlice({
    name: "roles",
    initialState,
    reducers: {
        getRolesReset(state) {
            state.roles = AsyncState.create()
        },
        getRolesProcess(state) {
            state.roles = state.roles.toProcess()
        },
        getRolesSuccess(state, action: PayloadAction<Role[]>) {
            state.roles = state.roles.toSuccess(action.payload)
        },
        getRolesFailed(state, action: PayloadAction<SystemError>) {
            state.roles = state.roles.toFailed(action.payload)
        },
        getAllRoleSettingsProcess(state) {
            state.allRoleSettings = state.allRoleSettings.toProcess()
        },
        getAllRoleSettingsSuccess(state, action: PayloadAction<AllRoleSettings>) {
            state.allRoleSettings = state.allRoleSettings.toSuccess(action.payload)
        },
        getAllRoleSettingsFailed(state, action: PayloadAction<SystemError>) {
            state.allRoleSettings = state.allRoleSettings.toFailed(action.payload)
        },
        getAllGlobalPermissionsProcess(state) {
            state.allGlobalPermissions = state.allGlobalPermissions.toProcess()
        },
        getAllGlobalPermissionsSuccess(state, action: PayloadAction<string[]>) {
            state.allGlobalPermissions = state.allGlobalPermissions.toSuccess(action.payload)
        },
        getAllGlobalPermissionsFailed(state, action: PayloadAction<SystemError>) {
            state.allGlobalPermissions = state.allGlobalPermissions.toFailed(action.payload)
        },
        createRoleProcess(state) {
            state.createRole = state.createRole.toProcess()
        },
        createRoleSuccess(state, action: PayloadAction<Role>) {
            state.createRole = state.createRole.toSuccess(action.payload)
            state.roles = state.roles.map(v => [...v, action.payload])
        },
        createRoleFailed(state, action: PayloadAction<SystemError>) {
            state.createRole = state.createRole.toFailed(action.payload)
        },
        updateRoleProcess(state) {
            state.updateRole = state.updateRole.toProcess()
        },
        updateRoleSuccess(state, action: PayloadAction<Role>) {
            state.updateRole = state.updateRole.toSuccess(action.payload)
            state.roles = state.roles.map(v => v.map(r => (r.Id === action.payload.Id ? action.payload : r)))
        },
        updateRoleFailed(state, action: PayloadAction<SystemError>) {
            state.updateRole = state.updateRole.toFailed(action.payload)
        },
        updateManyRolesProcess(state) {
            state.updateManyRoles = state.updateManyRoles.toProcess()
        },
        updateManyRolesSuccess(state, action: PayloadAction<Role[]>) {
            state.updateManyRoles = state.updateManyRoles.toSuccess(action.payload)
            state.roles = state.roles.map(roles => [...roles, ...action.payload])
        },
        updateManyRolesFailed(state, action: PayloadAction<SystemError>) {
            state.updateManyRoles = state.updateManyRoles.toFailed(action.payload)
        },
        deleteRoleProcess(state) {
            state.deleteRole = state.deleteRole.toProcess()
        },
        deleteRoleSuccess(state, action: PayloadAction<string>) {
            state.deleteRole = state.deleteRole.toSuccess()
            state.roles = state.roles.map(v => v.filter(r => r.Id !== action.payload))
        },
        deleteRoleFailed(state, action: PayloadAction<SystemError>) {
            state.deleteRole = state.deleteRole.toFailed(action.payload)
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export default roles.reducer

export const actions = roles.actions
