import { faRedo } from "@fortawesome/pro-light-svg-icons/faRedo"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import IconButton from "../../../../../../components/IconButton/IconButton"
import { SendingJob } from "../../../../../../models/massMailing"
import styles from "./MassMailingViewInfo.module.scss"
import { testId } from "../../../../../../utility/tests/testId"

const tNamespace = "massMailing:"
const tStatisticNamespace = `${tNamespace}statistic.`

export interface MassMailingViewInfoProps {
    className: string
    mailing: SendingJob
    isItemsLoadAllowed: boolean
    loadItems: () => void
}

export const MassMailingViewInfo = (props: MassMailingViewInfoProps) => {
    const { className, mailing, isItemsLoadAllowed, loadItems } = props

    const { t } = useTranslation()

    const fileName = t(`${tNamespace}file-name`, { title: mailing.Data.Title.replaceAll(" ", "_") }) + ".csv"

    return (
        <div className={cn(className, styles.info)}>
            {mailing.Data.CsvFileLink && (
                <a
                    href={mailing.Data.CsvFileLink + `?file_name=${fileName}`}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.info__fileLink}
                    data-testid={testId.downloadMassMailingFile}
                >
                    {fileName}
                </a>
            )}
            <div className={styles.info__statistic}>
                <div>
                    {t(`${tStatisticNamespace}total`)}: {mailing.Statistic.CountItems}
                </div>
                <div>
                    {t(`${tStatisticNamespace}error`)}: {mailing.Statistic.ErrorItems}
                </div>
                <div>
                    {t(`${tStatisticNamespace}delivered`)}: {mailing.Statistic.DeliveredItems}
                </div>
                <div>
                    {t(`${tStatisticNamespace}read`)}: {mailing.Statistic.ReadItems}
                </div>
                <IconButton
                    icon={faRedo}
                    className={cn(styles.info__loadBtn, !isItemsLoadAllowed && styles.info__loadBtn_hidden)}
                    onClick={loadItems}
                    variant="outline-primary"
                    data-testid={testId.reloadMassMailingItems}
                />
            </div>
        </div>
    )
}
