import {
    ApiEditMessage,
    EditEnum,
    GetUpdatedMessagesRequest,
    isApiMessage,
    MessageAttachment,
    MessageTextType,
    SendMessageRequest,
    Sticker
} from "../../models/Dialogs/message"
import { ThunkDispatch } from "redux-thunk"
import { AnyAction } from "redux"
import { dialogsApi } from "../../api/controllers/dialogs"
import { convertRequestToResponse } from "../message/convertRequestToResponse"
import { EditMessageBody } from "../../models/Dialogs/dialog"
import { v4 as uuidv4 } from "uuid"

export const updateDialogMessageEditType = (
    dispatch: ThunkDispatch<never, never, AnyAction>,
    currentMessagePagination: GetUpdatedMessagesRequest,
    messageIdToDelete: string,
    messageIdToCreate: string,
    editType: EditEnum,
    attachments: MessageAttachment[] = [],
    text?: string,
    sticker?: Sticker
) => {
    return dispatch(
        dialogsApi.util.updateQueryData("getDialogMessages", currentMessagePagination, draft => {
            const index = draft.Messages.findIndex(message => message.Fields.Id === messageIdToDelete)
            if (index !== -1) {
                const storedMessage = draft.Messages[index]
                if (isApiMessage(storedMessage)) {
                    draft.Messages.splice(index, 1)
                    switch (editType) {
                        case EditEnum.Deleted:
                            draft.Messages.push({
                                Case: storedMessage.Case,
                                Fields: {
                                    ...storedMessage.Fields,
                                    Id: messageIdToCreate,
                                    EditType: editType
                                }
                            })
                            break
                        case EditEnum.Edited:
                            const formattedMessage = {
                                ...storedMessage,
                                Fields: {
                                    ...storedMessage.Fields,
                                    Id: messageIdToCreate,
                                    EditType: editType,
                                    Attachments: attachments,
                                    Sticker: sticker
                                }
                            }
                            draft.Messages.push(formattedMessage)
                            break
                        default:
                            break
                    }
                }
            }
        })
    )
}

export const addEditMessageToDialogMessageQuery = (
    dispatch: ThunkDispatch<never, never, AnyAction>,
    currentMessagePagination: GetUpdatedMessagesRequest,
    operatorId: string,
    editMessage: EditMessageBody
) => {
    dispatch(
        dialogsApi.util.updateQueryData("getDialogMessages", currentMessagePagination, draft => {
            const editMessageRecord: ApiEditMessage = {
                Case: "EditedByOperator",
                Fields: {
                    Id: uuidv4(),
                    MessageType: 1,
                    TextType: MessageTextType.Markdown,
                    Direction: 1,
                    Sender: { Id: operatorId },
                    DialogId: editMessage.DialogId,
                    AddedDate: Date.now(),
                    Context: [[]],
                    Status: 0,
                    EditedMessageId: editMessage.Id,
                    Attachments: editMessage.Attachments ? editMessage.Attachments : [],
                    Sticker: editMessage.Sticker,
                    ChannelId: editMessage.ChannelId,
                    Meta: {},
                    Actions: [],
                    Text: editMessage.Text
                }
            }

            draft.Messages.push(editMessageRecord)
        })
    )
}

export const updateDialogMessageQuery = (
    dispatch: ThunkDispatch<never, never, AnyAction>,
    currentMessagePagination: GetUpdatedMessagesRequest,
    messageIdToDelete: string,
    messageIdToCreate: string,
    updateMessage: SendMessageRequest,
    operatorId: string
) => {
    return dispatch(
        dialogsApi.util.updateQueryData("getDialogMessages", currentMessagePagination, draft => {
            const index = draft.Messages.findIndex(message => message.Fields.Id === messageIdToDelete)
            if (index !== -1) {
                const draft1 = draft.Messages[index]
                if (isApiMessage(draft1) && updateMessage && operatorId) {
                    draft.Messages.splice(index, 1)
                    draft.Messages.push(convertRequestToResponse(updateMessage, operatorId, messageIdToCreate))
                }
            }
        })
    )
}
