import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    useListMassMailingReportsMutation,
    useMakeMassMailingReportMutation
} from "../../../../../api/controllers/massMailing"
import Can from "../../../../../components/Can/Can"
import FullscreenLoader from "../../../../../components/FullscreenLoader/FullscreenLoader"
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton"
import { ReportsList } from "../../../../../components/ReportsList/ReportsList"
import SidebarRight from "../../../../../components/SidebarRight/SidebarRight"
import { ModifyMassMailing } from "../../../../../permissions"
import { selectCurrentProjectId } from "../../../../../store/projects/selectors"
import { useAppSelector } from "../../../../../store/store"
import { testId } from "../../../../../utility/tests/testId"

export interface useMassMailingReportsProps {
    id: string
}

const tNamespace = "reports:"
const REPORTS_TOTAL = 1000

export const useMassMailingReports = ({ id }: useMassMailingReportsProps) => {
    const [isOpened, setIsOpened] = useState(false)
    const { t } = useTranslation()
    const projectId = useAppSelector(selectCurrentProjectId)
    const [getReports, { isLoading: isLoadingReports, data: reports }] = useListMassMailingReportsMutation()
    const [makeReport, { isLoading: isLoadingReport }] = useMakeMassMailingReportMutation()

    const openReportsSidebar = useCallback(() => setIsOpened(true), [])
    const closeReportsSidebar = useCallback(() => setIsOpened(false), [])

    const makeReportHandler = async () => {
        if (projectId) {
            await makeReport({ CustomerId: projectId, SendingJobId: id })
            getReports({ Count: REPORTS_TOTAL, SendingJobId: id })
        }
    }

    useEffect(() => {
        if (id && isOpened) {
            getReports({ Count: REPORTS_TOTAL, SendingJobId: id })
        }
    }, [id, getReports, isOpened])

    const renderSidebar = useCallback(() => {
        return (
            <SidebarRight open={isOpened} onClose={closeReportsSidebar} title={t(`${tNamespace}many`)}>
                {isLoadingReports ? (
                    <FullscreenLoader />
                ) : (
                    <Can permission={ModifyMassMailing}>
                        <LoadingButton
                            loading={isLoadingReport}
                            onClick={makeReportHandler}
                            style={{ margin: "0 16px 20px" }}
                            data-testid={testId.makeNewMassMailingReport}
                        >
                            {t(`${tNamespace}make`)}
                        </LoadingButton>
                    </Can>
                )}
                {reports ? <ReportsList reports={reports.ReadyReports} /> : null}
            </SidebarRight>
        )
    }, [isOpened, isLoadingReports, isLoadingReport, reports, makeReportHandler])

    return {
        isOpened,
        openReportsSidebar,
        closeReportsSidebar,
        renderSidebar
    }
}
