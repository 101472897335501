import { faCheck, faPauseCircle } from "@fortawesome/pro-light-svg-icons"
import { Button } from "react-bootstrap"
import Can from "../../../../../../components/Can/Can"
import { formTranslation } from "../../../../../../locales/form"
import { MassMailingUserStatuses, SendingJob } from "../../../../../../models/massMailing"
import { ModifyMassMailing } from "../../../../../../permissions"
import { deleteMassMailing, pauseMassMailing, startMassMailing } from "../../../../../../store/massMailing/thunks"
import { Time } from "../../../../../../utility/common/time"
import styles from "./MassMailingViewHeader.module.scss"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../../../../store/store"
import IconButton from "../../../../../../components/IconButton/IconButton"
import { testId } from "../../../../../../utility/tests/testId"

const tNamespace = "massMailing:"
const tStatusNamespace = `${tNamespace}status.`
const tButtonsNamespace = `${tNamespace}buttons.`

export interface MassMailingViewHeaderProps {
    className: string
    openReportsSidebar: () => void
    status: MassMailingUserStatuses
    mailing: SendingJob
}

export const MassMailingViewHeader = (props: MassMailingViewHeaderProps) => {
    const { className, openReportsSidebar, status, mailing } = props

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const title = `${mailing.Data.Title} - ${Time.stampToLocalDate(
        mailing.Data.ScheduleTime ?? mailing.Audit.CreateTimestamp
    )}`
    const isInactive = status === MassMailingUserStatuses.sheduled || status === MassMailingUserStatuses.paused

    return (
        <div className={cn(className, styles.header)}>
            <h1 className={styles.header__title}>{title}</h1>
            <div className={styles.header__status}>
                <div className={cn(styles.header__indicator, styles[`header__indicator_${status}`])} />
                {t(`${tStatusNamespace}${status}`)}
            </div>
            <div className={styles.header__btns}>
                <Can permission={ModifyMassMailing}>
                    {isInactive && (
                        <Button
                            variant="primary"
                            onClick={() => dispatch(deleteMassMailing(mailing))}
                            data-testid={testId.deleteMassMailing}
                        >
                            {t(formTranslation.delete)}
                        </Button>
                    )}
                    {status === MassMailingUserStatuses.paused && (
                        <IconButton
                            variant="outline-primary"
                            icon={faCheck}
                            onClick={() => dispatch(startMassMailing(mailing))}
                            data-testid={testId.startMassMailing}
                        >
                            {t(`${tButtonsNamespace}start`)}
                        </IconButton>
                    )}
                    {status === MassMailingUserStatuses.active && (
                        <IconButton
                            variant="outline-primary"
                            icon={faPauseCircle}
                            onClick={() => dispatch(pauseMassMailing(mailing))}
                            data-testid={testId.pauseMassMailing}
                        >
                            {t(`${tButtonsNamespace}pause`)}
                        </IconButton>
                    )}
                </Can>
                {status !== MassMailingUserStatuses.sheduled && (
                    <Button
                        variant="outline-primary"
                        onClick={openReportsSidebar}
                        data-testid={testId.openMassMailingReports}
                    >
                        {t(`${tButtonsNamespace}reports`)}
                    </Button>
                )}
            </div>
        </div>
    )
}
