import { MenuItem } from "../../models/menu"
import { OldMenuPermissions } from "../menu/oldMenuPermissions"
import { isPermissionExists } from "../common/usePermissionsCheck"
import { isRefExists } from "../common/useRefCheck"
import { Reference } from "../../models/reference"

export const getAllowedMenuItems = (items: MenuItem[], currentPermissions: Set<string>, references?: Reference[]) => {
    return items.filter(menuItem => {
        if (!menuItem.role && !menuItem.reference) {
            return true
        }
        let roles: string[] = []
        const localMenuPermission = OldMenuPermissions[menuItem.id]
        if (localMenuPermission) {
            roles.push(localMenuPermission)
        }

        if (Array.isArray(menuItem.role)) {
            roles = roles.concat(menuItem.role)
        } else if (menuItem.role) {
            roles.push(menuItem.role)
        }

        if (menuItem.rolesValidator) {
            return menuItem.rolesValidator(roles, currentPermissions)
        }

        const isRolesFit = roles.every(p => isPermissionExists(p, currentPermissions))
        let isRefsFit = true

        if (menuItem.reference) {
            isRefsFit = references ? isRefExists(menuItem.reference, references) : false
        }

        return isRolesFit && isRefsFit
    })
}
