import {
    MassMailingItemUserStatuses,
    MassMailingUserStatuses,
    SendingJob,
    SendingJobItem,
    SendingJobItemStatus,
    SendingJobRunMode,
    SendingJobRunStatus
} from "../../models/massMailing"

export const getMassMailingStatus = (mailing: SendingJob) => {
    if (mailing.Deleted) {
        return MassMailingUserStatuses.deleted
    }

    switch (mailing.State.RunStatus) {
        case SendingJobRunStatus.Inactive:
            return mailing.Data.RunMode === SendingJobRunMode.Schedule
                ? MassMailingUserStatuses.sheduled
                : MassMailingUserStatuses.paused
        case SendingJobRunStatus.Active:
            return MassMailingUserStatuses.active
        case SendingJobRunStatus.Completed:
            return MassMailingUserStatuses.finished
    }
}

export const getMassMailingItemStatus = (item: SendingJobItem) => {
    if (item.Deleted) {
        return MassMailingItemUserStatuses.deleted
    }

    if (item.State.Errors) {
        return MassMailingItemUserStatuses.error
    }

    switch (item.State.SendingStatus) {
        case SendingJobItemStatus.NotProcessed:
            return MassMailingItemUserStatuses.inactive
        case SendingJobItemStatus.SentAndNeedCascade:
            return MassMailingItemUserStatuses.cascade
        case SendingJobItemStatus.Completed:
            return MassMailingItemUserStatuses.completed
    }
}
