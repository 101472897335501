import { ReadyReport } from "../../models/reports"
import styles from "./ReportsList.module.scss"
import { Report } from "./components/Report"

export interface ReportsListProps {
    reports: ReadyReport[]
}

export const ReportsList = ({ reports }: ReportsListProps) => {
    return (
        <ul className={styles.reportsList}>
            {reports.map((report, n) => (
                <li key={report.ReadyReportId} className={styles.reportsList__item}>
                    <Report report={report} index={reports.length - n} />
                </li>
            ))}
        </ul>
    )
}
