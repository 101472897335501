import { useEffect } from "react"
import { useSearchMassMailingMutation } from "../../../../api/controllers/massMailing"
import SearchInput from "../../../../components/SearchInput/SearchInput"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import { useAppSelector } from "../../../../store/store"
import { testId } from "../../../../utility/tests/testId"

export const MassMailingSearchInput = () => {
    const [search, { isLoading }] = useSearchMassMailingMutation()
    const projectId = useAppSelector(selectCurrentProjectId)
    const filter = {
        ProjectId: String(projectId),
        DateCreateFrom: 0,
        DateCreateTo: Date.now()
    }

    useEffect(() => {
        if (projectId) {
            search(filter)
        }
    }, [search, projectId])

    const handleChange = (query: string) => {
        if (projectId) {
            search(query ? { ...filter, TitleFilter: query } : filter)
        }
    }

    return <SearchInput onChange={handleChange} isLoading={isLoading} data-testid={testId.massMailingSearchInput} />
}
