import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useLocation, useParams, useRouteMatch } from "react-router-dom"
import Async from "../Async/Async"
import {
    getArticle,
    getRelatedCategories,
    refreshArticle,
    sendArticleForApproval,
    updateArticle,
    updateArticlePermissions,
    updateScenario
} from "../../store/knowledgeBase/thunks"
import {
    selectCurrentArticle,
    selectCurrentArticleState,
    selectCurrentBranch,
    selectUpdateArticleState,
    selectArticleMarkedMessagesRemovedIds
} from "../../store/knowledgeBase/selectors"
import {
    Article,
    ArticleAction,
    ArticleAnswer,
    ArticleEditFormValues,
    ArticleKind,
    ArticleShareFormValues,
    MarkedMessagesReserve
} from "../../models/article"
import ArticleViewLoader from "./ArticleViewLoader"
import ArticleView from "./ArticleView"
import { FormikProps } from "formik/dist/types"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ModifyKnowledgeBaseSubscriptions } from "../../permissions"
import ScenarioArticle from "../ScenarioArticle/ScenarioArticle"
import { Scenario } from "../../models/scenario"
import AsyncWithoutData from "../Async/AsyncWithoutData"
import { getCurrentSubscriptions } from "../../store/knowledgeBaseSubscriptions/thunks"
import { useNotificationExists } from "../../utility/knowledgeBase/useNotificationExists"
import ArticleBlocked from "../ArticleBlocked/ArticleBlocked"
import { selectCurrentUserLogin } from "../../store/users/selectors"
import FullscreenError from "../FullscreenError/FullscreenError"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import ArticleViewProcessor from "./ArticleViewProcessor"
import { push } from "connected-react-router"
import { buildArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"
import {
    getArticleProcess,
    setArticleWorkMode,
    mutateCurrentBranch,
    clearCurrentBranch,
    updateArticleProcess,
    actions as storeActions,
    clearCurrentArticle
} from "../../store/knowledgeBase/slice"
import { handleSetArticleMarkedQuestionsReserve } from "../../store/knowledgeBase/actions"
import ArticleViewProvider from "../ArticleViewProvider/ArticleViewProvider"
import { ARTICLE_SAVE_DELAY } from "../../utility/knowledgeBase/constants"
import { mapArticleActionToWorkMode } from "../../utility/knowledgeBase/articleWorkMode"
import { ArticleViewContextType } from "../ArticleViewProvider/ArticleViewContext"
import type { TreeItem } from "@atlaskit/tree/types"
import ArticleActions from "../ArticleActions/ArticleActions"
import useDocumentTitle from "../../utility/common/useDocumentTitle"
import { CatalogPlacement } from "../Catalogs/Catalogs"
import { handleRemoveMessageMarkup } from "../../utility/knowledgeBase/sampleQuestions"

const tNamespace = "knowledgeBase:"

interface ArticleViewContainerProps {
    onSubscribeToArticle: (articleCode: string) => void
    onUnsubscribeFromArticle: (articleCode: string) => void
    onOpenArticleInfoForm: (article: Article, isEdit?: boolean) => void
    onOpenSurveyForm: (article: Article, isEdit?: boolean) => void
    /*
      Что тут происходит: 
      [handleChangeCurrentAnswer, handleRelatedArticleClick, handleReloadArticle]
      Следующие 3 экшена сильно зависимы от стейта урла на странице pages/KnowledgeBase,
      нам же нужно это слегка переписать, чтобы вставлять базу знаний на другие страницы, с отличными урлами
      от дефолтной KnowledgeBase, причем там, где нельзя менять эти урлы, кликая на кнопки

      Логика очень простая: если мы прокинули пропсом этот экшен, то юзаем его, если нет, то юзаем дефолтную логику
      такую же как на странице pages/KnowledgeBase с роутингом

      В данный момент этот переписанный функционал используется в pages/Dialogs в pages/KnowledgeBaseInWorkplace
    */
    handleChangeCurrentAnswer?: (answerId: string) => void
    handleRelatedArticleClick?: (category: TreeItem) => void
    handleReloadArticle?: () => void
    disableAddBlockBtn: boolean
    connected: boolean
    articlePlacement?: CatalogPlacement
}

const ArticleViewContainer: React.FC<ArticleViewContainerProps> = props => {
    const {
        onSubscribeToArticle,
        onUnsubscribeFromArticle,
        onOpenArticleInfoForm,
        onOpenSurveyForm,
        disableAddBlockBtn,
        connected,
        handleChangeCurrentAnswer: handleChangeCurrentAnswerFromProps,
        handleRelatedArticleClick: handleRelatedArticleClickFromProps,
        handleReloadArticle: handleReloadArticleFromProps,
        articlePlacement
    } = props
    const { action, code } = useParams<{ action: ArticleAction; code: string }>()
    const { search } = useLocation()
    const { url } = useRouteMatch()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const formRef = useRef<FormikProps<ArticleEditFormValues>>(null)
    const articleState = useSelector(selectCurrentArticleState)
    const currentArticle = useSelector(selectCurrentArticle)
    const updateArticleState = useSelector(selectUpdateArticleState)
    const markedMessagesRemovedIds = useSelector(selectArticleMarkedMessagesRemovedIds)
    const login = useSelector(selectCurrentUserLogin)
    const { CurrentCatalogCode, CurrentCategoryId } = useSelector(selectCurrentBranch, shallowEqual)
    const notificationExists = useNotificationExists()
    const [transformToScenario, setTransformToScenario] = useState(false)
    const canModifySubscriptions = usePermissionsCheck([ModifyKnowledgeBaseSubscriptions])
    const { projectId, url: knowledgeBaseUrl } = useContext(KnowledgeBaseContext)
    const [isArticleExpanded, setIsArticleExpanded] = useState<boolean>(currentArticle?.Article?.Expanded ?? false)

    useEffect(() => {
        dispatch(setArticleWorkMode(mapArticleActionToWorkMode(action)))
    }, [action, dispatch])

    useEffect(() => {
        projectId && code && login && dispatch(getArticle(projectId, login, code, true))
    }, [dispatch, projectId, login, code])

    useEffect(() => {
        const actualArticleCode = code ?? currentArticle?.Article?.SymbolCode

        connected && actualArticleCode && onSubscribeToArticle(actualArticleCode)
        return () => {
            connected && code && onUnsubscribeFromArticle(code)
        }
    }, [code, onSubscribeToArticle, onUnsubscribeFromArticle, connected, currentArticle])

    useEffect(() => {
        setTransformToScenario(false)
    }, [dispatch, projectId, code, action])

    useEffect(() => {
        projectId && code && canModifySubscriptions && dispatch(getCurrentSubscriptions(projectId, code))
    }, [canModifySubscriptions, code, dispatch, projectId])

    useEffect(() => {
        if (projectId && currentArticle?.Article) {
            dispatch(
                getRelatedCategories(
                    projectId,
                    CurrentCategoryId,
                    currentArticle.Article.SymbolCode,
                    CurrentCatalogCode
                )
            )
        }
    }, [projectId, currentArticle, dispatch])

    useEffect(() => {
        return () => {
            dispatch(clearCurrentBranch())
            dispatch(clearCurrentArticle())
        }
    }, [dispatch])

    const handleReloadArticle = useCallback(() => {
        if (handleReloadArticleFromProps) {
            handleReloadArticleFromProps()
            return
        }
        projectId && code && login && dispatch(getArticle(projectId, login, code, false))
    }, [dispatch, projectId, login, code, handleReloadArticleFromProps])

    const handleUpdateArticleClick = useCallback(() => {
        if (currentArticle?.Article?.SymbolCode) {
            if (markedMessagesRemovedIds.length > 0) {
                handleRemoveMessageMarkup(dispatch)(currentArticle?.Article?.SymbolCode, markedMessagesRemovedIds)
                const emptyReservedData: MarkedMessagesReserve = {
                    Questions: [],
                    Count: 0,
                    RemovedIds: []
                }
                handleSetArticleMarkedQuestionsReserve(emptyReservedData, dispatch)
            }
        }
        formRef.current?.handleSubmit()
    }, [currentArticle?.Article?.SymbolCode, markedMessagesRemovedIds, formRef])

    const handleUpdateArticle = useCallback(
        (values: ArticleEditFormValues) => {
            const id = articleState.data?.Article?.Id

            dispatch(updateArticleProcess())
            dispatch(getArticleProcess())

            setTimeout(() => {
                if (projectId && code && id) {
                    dispatch(updateArticle(projectId, url, code, values, id))
                }
            }, ARTICLE_SAVE_DELAY)
        },
        [dispatch, projectId, code, url, articleState.data]
    )

    const handleUpdateScenario = useCallback(
        (values: ArticleEditFormValues, scenario: Scenario, versionId: string) => {
            projectId && code && dispatch(updateScenario(projectId, url, code, values, scenario, versionId))
        },
        [code, dispatch, projectId, url]
    )

    const handleSendArticleForApproval = useCallback(() => {
        const id = articleState.data?.Article?.Id
        projectId && code && id && dispatch(sendArticleForApproval(projectId, url, code, id))
    }, [dispatch, projectId, code, url, articleState.data])

    const handleShare = useCallback(
        (values: ArticleShareFormValues, hidePopover: () => void) => {
            projectId &&
                articleState.data?.Article &&
                dispatch(
                    updateArticlePermissions(
                        projectId,
                        url,
                        code,
                        values.Permissions,
                        values.ChangesInheritance,
                        articleState.data.Article.Id,
                        hidePopover
                    )
                )
        },
        [projectId, dispatch, code, url, articleState.data]
    )

    const handleTransformToScenario = useCallback(() => {
        setTransformToScenario(true)
    }, [setTransformToScenario])

    const handleRefreshArticle = useCallback(() => {
        projectId && code && dispatch(refreshArticle(projectId, url, code))
    }, [dispatch, projectId, code, url])

    const handleRelatedArticleClick = useCallback(
        (category: TreeItem) => {
            if (handleRelatedArticleClickFromProps) {
                handleRelatedArticleClickFromProps(category)
                return
            }

            const {
                id,
                data: { symbolCode, catalogCode }
            } = category

            dispatch(push(buildArticleActionUrl(knowledgeBaseUrl, ArticleAction.View, symbolCode)))
            dispatch(
                mutateCurrentBranch({
                    CurrentCategoryId: id as string,
                    CurrentCatalogCode: catalogCode
                })
            )
        },
        [dispatch, knowledgeBaseUrl, handleRelatedArticleClickFromProps]
    )

    const handleSelectAnswer = useCallback(
        (answerId: string) => {
            handleChangeCurrentAnswerFromProps
                ? handleChangeCurrentAnswerFromProps(answerId)
                : dispatch(push(`${url}/${answerId}${search}`))
        },
        [dispatch, search, url, handleChangeCurrentAnswerFromProps]
    )

    const handleChangeCurrentAnswer = useCallback(
        (answerId: string) => {
            handleChangeCurrentAnswerFromProps
                ? handleChangeCurrentAnswerFromProps(answerId)
                : dispatch(storeActions.updateCurrentAnswerById(answerId))
        },
        [dispatch, handleChangeCurrentAnswerFromProps]
    )

    const handleUpdateAnswer = useCallback(
        (answer: ArticleAnswer) => {
            dispatch(storeActions.updateCurrentAnswer(answer))
        },
        [dispatch]
    )

    const handleExpandArticle = useCallback(() => {
        setIsArticleExpanded(isArticleExpanded => !isArticleExpanded)
    }, [])

    useEffect(() => {
        isArticleExpanded !== undefined && dispatch(storeActions.setArticleExpanded(isArticleExpanded))
    }, [dispatch, isArticleExpanded])

    useEffect(() => {
        currentArticle?.Article && setIsArticleExpanded(currentArticle?.Article?.Expanded)
    }, [currentArticle])

    const isEdit = action === ArticleAction.Edit

    const articleViewContextValue: ArticleViewContextType = {
        availableKinds: [ArticleKind.Common, ArticleKind.Scenario],
        connected: connected,
        onShare: handleShare
    }

    const {
        modalOpen,
        openModal: openApproveModal,
        closeModal,
        onExited
    } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}send-article-for-approval-alert.title`)}
            message={t(`${tNamespace}send-article-for-approval-alert.message`)}
            submitBtnText={t(formTranslation.send)}
            onClose={closeModal}
            onSubmit={handleSendArticleForApproval}
            variant="primary"
            onExited={onExited}
        />
    ))

    useDocumentTitle(articleState?.data?.Article?.Title)

    return (
        <AsyncWithoutData
            state={updateArticleState}
            processView={<ArticleViewLoader />}
            errorView={({ message }) => <FullscreenError message={message} hideLogo />}
        >
            <Async
                dataState={articleState}
                processView={<ArticleViewLoader />}
                errorView={({ message }) => <FullscreenError message={message} hideLogo />}
            >
                {({ data: { Article, PermittedAction } }) => (
                    <ArticleViewProvider {...articleViewContextValue} permittedAction={PermittedAction}>
                        <ArticleViewProcessor
                            article={Article}
                            permittedAction={PermittedAction}
                            articleCode={code}
                            transformToScenario={
                                articlePlacement === CatalogPlacement.workplace ? false : transformToScenario
                            }
                            blocked={<ArticleBlocked onReloadArticle={handleReloadArticle} />}
                            scenario={(article, canModify) => (
                                <ScenarioArticle
                                    article={article}
                                    formRef={formRef}
                                    isEdit={articlePlacement === CatalogPlacement.workplace ? false : isEdit}
                                    canEdit={articlePlacement === CatalogPlacement.workplace ? false : canModify}
                                    transformToScenario={
                                        articlePlacement === CatalogPlacement.workplace ? false : transformToScenario
                                    }
                                    onUpdate={handleUpdateScenario}
                                    onRelatedArticleClick={handleRelatedArticleClick}
                                    articlePlacement={articlePlacement}
                                    actions={
                                        articlePlacement !== CatalogPlacement.workplace ? (
                                            <ArticleActions
                                                code={code}
                                                article={article}
                                                isEdit={isEdit}
                                                onSave={handleUpdateArticleClick}
                                                disableEditButtons={!canModify}
                                                onOpenApproveModal={openApproveModal}
                                                disableAddBlockBtn={disableAddBlockBtn}
                                                onOpenInfo={onOpenArticleInfoForm}
                                                onOpenSurvey={onOpenSurveyForm}
                                                isScenario
                                            />
                                        ) : undefined
                                    }
                                />
                            )}
                            classic={(article, canModify) => (
                                <ArticleView
                                    article={article}
                                    formRef={formRef}
                                    isEdit={articlePlacement === CatalogPlacement.workplace ? false : isEdit}
                                    canEdit={articlePlacement === CatalogPlacement.workplace ? false : canModify}
                                    notificationExists={notificationExists}
                                    onUpdateArticle={handleUpdateArticle}
                                    onTransformToScenario={handleTransformToScenario}
                                    onRelatedArticleClick={handleRelatedArticleClick}
                                    onSelectAnswer={handleSelectAnswer}
                                    onChangeCurrentAnswer={handleChangeCurrentAnswer}
                                    onUpdateAnswer={handleUpdateAnswer}
                                    articlePlacement={articlePlacement}
                                    articleActions={
                                        articlePlacement !== CatalogPlacement.workplace ? (
                                            <ArticleActions
                                                code={code}
                                                article={article}
                                                isEdit={isEdit}
                                                onSave={handleUpdateArticleClick}
                                                disableEditButtons={!canModify}
                                                onOpenApproveModal={openApproveModal}
                                                onRefresh={
                                                    article.Kind === ArticleKind.External
                                                        ? handleRefreshArticle
                                                        : undefined
                                                }
                                                onOpenInfo={onOpenArticleInfoForm}
                                                onOpenSurvey={onOpenSurveyForm}
                                                onExpandArticle={handleExpandArticle}
                                            />
                                        ) : undefined
                                    }
                                />
                            )}
                        />
                    </ArticleViewProvider>
                )}
            </Async>
        </AsyncWithoutData>
    )
}

export default ArticleViewContainer
