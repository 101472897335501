import { createApi } from "@reduxjs/toolkit/dist/query/react"
import tags from "./tags"
import dynamicBaseQuery from "./dynamicBaseQuery"

export const queryApiReducerPath = "queryApi" as const
export const queryApi = createApi({
    baseQuery: dynamicBaseQuery,
    reducerPath: queryApiReducerPath,
    tagTypes: tags,
    endpoints: () => ({})
})
