import cn from "classnames"
import { push } from "connected-react-router"
import { useTranslation } from "react-i18next"
import { useRouteMatch } from "react-router-dom"
import { SendingJob } from "../../../../models/massMailing"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { Time } from "../../../../utility/common/time"
import { getMassMailingStatus } from "../../helpers"
import styles from "./MassMailingListItem.module.scss"
import { testId } from "../../../../utility/tests/testId"

export interface MassMailingListItemProps {
    item: SendingJob
}

const tNamespace = "massMailing:status."

export const MassMailingListItem = ({ item }: MassMailingListItemProps) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const projectId = useAppSelector(selectCurrentProjectId)
    const itemPath = `/project/${projectId}/mass-mailing/${item.Id}`
    const isActive = Boolean(useRouteMatch(itemPath))
    const status = getMassMailingStatus(item)

    return (
        <div
            className={cn(styles.item, isActive && styles.item_active)}
            onClick={() => dispatch(push(itemPath))}
            data-testid={`${testId.massMailingListItem}-${item.Id}`}
        >
            <div className={styles.item__title}>{item.Data.Title}</div>
            <div className={styles.item__bottom}>
                <div>{Time.stampToLocalDate(item.Data.ScheduleTime ?? item.Audit.CreateTimestamp)}</div>
                <div className={styles.item__status}>
                    <div className={cn(styles.item__indicator, styles[`item__indicator_${status}`])} />
                    {t(`${tNamespace}${status}`)}
                </div>
            </div>
        </div>
    )
}
