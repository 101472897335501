import React, { useCallback, useMemo, useState } from "react"
import { Button, Modal, ModalProps } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import DatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import ru from "date-fns/locale/ru"
import enGb from "date-fns/locale/en-GB"
import styles from "./CalendarModal.module.scss"
import "./CalendarModalDatepicker.scss"

registerLocale("ru", ru)
registerLocale("en", enGb)

const tNamespace = "common:calendar."

export type PickerProps = Pick<ReactDatePickerProps, "minDate" | "selectsRange" | "showTimeInput">

export interface CalendarModalProps extends ModalProps, PickerProps {
    show: boolean
    onClose: () => void
    onSubmit: (dates: Date[]) => void
    datesBetween?: boolean
}

const getDatesBetween = (start: Date, end: Date) => {
    const dates: Date[] = []
    const theDate = new Date(start)

    while (theDate < end) {
        dates.push(new Date(theDate))
        theDate.setDate(theDate.getDate() + 1)
    }
    dates.push(end)

    return dates
}

const CalendarModal = (props: CalendarModalProps) => {
    const { t, i18n } = useTranslation()
    const {
        show,
        onClose,
        onSubmit,
        datesBetween,
        minDate,
        selectsRange = false,
        showTimeInput = false,
        ...modalProps
    } = props

    const now = useMemo(() => new Date(), [])
    const [startDate, setStartDate] = useState<Date>(now)
    const [endDate, setEndDate] = useState<Date | null>(null)

    const handleSubmit = useCallback(() => {
        if (!endDate) {
            onSubmit([startDate])
        } else {
            onSubmit(datesBetween ? getDatesBetween(startDate, endDate) : [startDate, endDate])
        }
        onClose()
    }, [datesBetween, startDate, endDate, onSubmit, onClose])

    const handleChange = useCallback((dates: [Date, Date] | Date | null) => {
        if (dates) {
            if (Array.isArray(dates)) {
                const [start, end] = dates
                setStartDate(start)
                setEndDate(end)
            } else {
                setStartDate(dates)
                setEndDate(null)
            }
        }
    }, [])

    return (
        <Modal {...modalProps} dialogClassName={styles["calendar-modal"]} show={show} onHide={onClose}>
            <div className={styles["calendar-modal__content"]}>
                <div className={styles["calendar-modal__sidebar"]}>
                    <MenuItemRow
                        title={t(`${tNamespace}today`)}
                        onClick={() => {
                            setStartDate(now)
                            setEndDate(null)
                        }}
                    />
                </div>
                <div className={styles["calendar-modal__date-picker"]}>
                    <DatePicker
                        adjustDateOnChange
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleChange}
                        monthsShown={2}
                        locale={i18n.language}
                        selectsRange={selectsRange}
                        showTimeInput={showTimeInput}
                        timeInputLabel={t(`${tNamespace}time`)}
                        minDate={minDate}
                        inline
                        calendarClassName="calendar-datepicker"
                        disabledKeyboardNavigation
                    />
                </div>
            </div>

            <Modal.Footer>
                <Button variant="light" onClick={onClose}>
                    {t(formTranslation.cancel)}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {t(formTranslation.apply)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CalendarModal
