import { RootState } from "../rootReducer"

export const selectOperatorDialogsState = (state: RootState) => state.dialogs.operatorDialogs
export const selectDialogId = (state: RootState) => state.dialogs.selectedDialogId
export const selectDialogMessagesState = (state: RootState) => state.dialogs.messages
export const selectOperatorClientId = (state: RootState) => state.dialogs.selectedOperatorClientId
export const selectMessagesPaginationState = (state: RootState) => state.dialogs.messagesPaginationState
export const selectSearchOperatorByCriterionPrevArgs = (state: RootState) =>
    state.dialogs.searchOperatorByCriterionPrevArgs
export const selectSearchOperatorByCriterionResponse = (state: RootState) =>
    state.dialogs.searchOperatorByCriterionResponse
export const selectDialogTopicsState = (state: RootState) => state.dialogs.topics
export const selectDialogKnowledgeBaseState = (state: RootState) => state.dialogs.knowledgeBase
export const selectDialogFastRepliesState = (state: RootState) => state.dialogs.fastReplies
export const selectDialogActiveState = (state: RootState) => !!state.dialogs.selectedDialogId
export const selectMessageInput = (state: RootState) => state.dialogs.inputMessage
export const selectDraftedInput = (state: RootState) => state.dialogs.inputDrafted
export const selectAIAssistHintMessage = (state: RootState) => state.dialogs.aiAssistHint
export const selectAIAssistRequestTypes = (state: RootState) => state.dialogs.aiAssistRequestTypes
export const selectAISuggest = (state: RootState) => state.dialogs.aiSuggest
export const selectSearchingState = (state: RootState) => Boolean(state.dialogs.searchCriterion)
export const selectSearchData = (state: RootState) => state.dialogs.searchData
export const selectIsKnowledgeBaseSidebarOpened = (state: RootState) =>
    state.dialogs.knowledgeBase.isKnowledgeBaseSidebarOpened
export const selectIsArticleSidebarOpened = (state: RootState) => state.dialogs.knowledgeBase.isArticleSidebarOpened
export const selectIsDialogCreating = (state: RootState) => state.dialogs.dialogCreate.creatingDialog
export const selectIsClientCreateSidebarOpened = (state: RootState) =>
    state.dialogs.dialogCreate.isCreatingClientSidebarOpened
export const selectIsChannelSelectSidebarOpened = (state: RootState) =>
    state.dialogs.dialogCreate.isChannelSelectSidebarOpened
export const selectCreateDialogOutgoingData = (state: RootState) => state.dialogs.dialogCreate.dialogCreateOutgoingData
export const selectLastMessageChannel = (state: RootState) => state.dialogs.lastMessageChannel
export const selectInputEditMessageData = (state: RootState) => state.dialogs.inputEditMessageData
export const selectForceReadonly = (state: RootState) => state.dialogs.forceReadonly
export const selectInputMaximized = (state: RootState) => state.dialogs.isInputMaximized
export const selectRMECommands = (state: RootState) => state.dialogs.RMECommands
export const selectDialogEmailData = (state: RootState) => state.dialogs.dialogEmailData
export const selectCurrentOmniUserId = (state: RootState) => state.dialogs.currentOmniUserId
